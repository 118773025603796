import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shortenName } from '../../utils/general'
import { dayMonthFilter } from '../../utils/time'
import CardModal from '../../components/CardModal'
import KpiDropdownMenu from '../../components/KPI/KpiDropdownMenu'
import AddEditKPIModal from '../../components/KPI/AddEditKPIModal'
import KPIUpdatesModal from '../../components/KPI/KPIUpdatesModal'
import UpdateKPIModal from '../../components/KPI/UpdateKPIModal'
import styled from 'styled-components'
import { objectiveColors, hexToRGBA, levelOpacity } from '../../utils/colors'
import { getFirstLetters } from '../../utils/name'

const NameBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  color: #0926d5;
  border: 2px solid #e4e7fd;
  font-weight: bold;
  font-size: 16px;
  background-color: #e4e7fd;
`

const KpiTableHeader = ({ t, depth }) => (
  <div className="grid-section-new-table" style={{ marginBottom: '1rem' }}>
    <div
      className="grid-kpi-report-new-table-header"
      style={{ paddingLeft: `${(depth + 1) * 24 + 45}px` }}
    >
      {t('kpi.kpi')}
    </div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.target')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.latestValue')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.madeBy')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.notes')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.ownerABS')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.status')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.topic')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.frequencyABS')}</div>
    <div className="grid-kpi-report-new-table-header">{t('kpi.updatedOnABS')}</div>
  </div>
)

const RagList = ({ reportStats, level }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '0.5rem',
        margin: 'auto 0',
        fontSize: '15px',
        marginLeft: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={'rag-letter-box red-rag-box'} style={{ height: '1.2rem', width: '1.2rem' }}>
          <span className="icon-size">R</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.red / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>({reportStats?.sumKpiReportStats?.red ?? 0})</span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box amber-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span className="icon-size">A</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.yellow / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.yellow ?? 0})
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box green-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span className="icon-size">G</span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.green / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.green ?? 0})
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={'rag-letter-box gray-rag-box'}
          style={{ height: '1.2rem', width: '1.2rem' }}
        >
          <span className="icon-size"></span>
        </div>
        <div style={{ marginLeft: '0.3rem', display: 'flex', flexDirection: 'row', width: '60px' }}>
          <span>
            {!reportStats?.sumKpiReportStats?.sum || reportStats?.sumKpiReportStats?.sum <= 0
              ? '0'
              : (
                  (reportStats.sumKpiReportStats.grey / reportStats.sumKpiReportStats.sum) *
                  100
                ).toFixed(0)}
            %
          </span>
          <span style={{ marginLeft: '0.1rem' }}>
            ({reportStats?.sumKpiReportStats?.grey ?? 0})
          </span>
        </div>
      </div>
    </div>
  )
}

const KpiReportNewTable = ({ data, expandAll = false, reload = () => {} }) => {
  const [expandedItems, setExpandedItems] = useState(new Set())
  const [isOpenCardModal, setIsOpenCardModal] = useState(false)
  const [cardModalUmtId, setCardModalUmtId] = useState(0)
  const [cardModalTopicType, setCardModalTopicType] = useState(0)
  const [selectedKpiUpdate, setSelectedKpiUpdate] = useState(null)
  const [selectedKpi, setSelectedKpi] = useState(null)
  const [addEditModalMode, setAddEditModalMode] = useState('')
  const [isOpenAddEditKPIModal, setIsOpenAddEditKPIModal] = useState(false)
  const [selectedObjId, setSelectedObjId] = useState(0)
  const [isOpenKPIUpdatesModal, setIsOpenKPIUpdatesModal] = useState(false)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const { t } = useTranslation(['Common'])

  useEffect(() => {
    if (expandAll) {
      expandAllItems()
    } else {
      collapseAll()
    }
  }, [expandAll])

  const toggleExpand = (id, parentPath = '') => {
    const uniqueId = `${parentPath}${id}`
    setExpandedItems((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(uniqueId)) {
        newSet.delete(uniqueId)
      } else {
        newSet.add(uniqueId)
      }
      return newSet
    })
  }

  const expandAllItems = () => {
    const expandedSet = new Set()
    const traverse = (obj, parentPath = '') => {
      if (!obj) return
      const uniqueId = `${parentPath}${obj.objective.id}`
      expandedSet.add(uniqueId)
      if (obj.objList?.length > 0) {
        obj.objList.forEach((childObj, index) => {
          traverse(childObj, `${uniqueId}-${childObj.objective.id}-`)
        })
      }
    }
    data.forEach((obj, index) => {
      traverse(obj, `root-${obj.objective.id}-`)
    })
    setExpandedItems(expandedSet)
  }

  const collapseAll = () => {
    setExpandedItems(new Set())
  }

  const convertFrequencyEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('kpi.weekly')
      case 2:
        return t('kpi.biweekly')
      case 3:
        return t('kpi.monthly')
      default:
        return t('kpi.unknown')
    }
  }

  const renderKpiSection = (kpiList, uniqueId, depth, obj) => {
    if (!kpiList || kpiList.length === 0) return null

    return (
      <div key={`${uniqueId}-kpi-section`}>
        <KpiTableHeader t={t} depth={depth} />
        {kpiList
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((kpi, kpiIndex) => (
            <div key={`${uniqueId}-kpi-${kpiIndex}`} className="grid-kpi-row kpi-row">
              <div className="grid-cell">
                <div className="kpi-cell" style={{ paddingLeft: `${(depth + 1) * 24}px` }}>
                  <span className="spacer"></span>
                  <div className="kpi-content">
                    <div className="kpi-name">{kpi.name}</div>
                  </div>
                </div>
              </div>
              <div className="grid-cell">
                {(kpi.kpiType === 1 || kpi.kpiType === 2) && (
                  <span>
                    {kpi.target}
                    {kpi.kpiType === 1 ? '%' : ' ' + kpi?.unit}
                    &nbsp;
                    {t('kpi.by')}
                    <br />
                    {kpi.targetDate}
                  </span>
                )}
              </div>
              <div className="grid-cell">
                <span>{kpi.lastUpdateTs == 0 ? '---' : kpi.lastUpdateAmount}</span>
              </div>
              <div className="grid-cell">
                <span>{kpi.lastUpdateTs == 0 ? '---' : shortenName(kpi.lastUpdateMadeBy)}</span>
              </div>
              <div className="grid-cell">
                <span>{kpi?.lastUpdateComment !== '' ? kpi?.lastUpdateComment : '---'}</span>
              </div>
              <div className="grid-cell">
                <span>{shortenName(kpi.ownerName)}</span>
              </div>
              <div className="grid-cell">
                <div
                  style={{
                    backgroundColor:
                      kpi.ragStatus === -1 || kpi.ragStatusWhenOverdue === -1
                        ? 'lightgray'
                        : kpi.ragStatus === 0
                          ? 'green'
                          : kpi.ragStatus === 1
                            ? 'gold'
                            : 'red',
                    width: '2rem',
                    height: '2rem',
                  }}
                ></div>
              </div>
              <div className="grid-cell">
                {(kpi.ragStatus === 0 || kpi.ragStatus === -1) && <span>{'---'}</span>}
                {kpi.lastUpdateUmtId > 0 && (kpi.ragStatus === 1 || kpi.ragStatus === 2) && (
                  <span
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCardModalUmtId(kpi.lastUpdateUmtId)
                      setCardModalTopicType(kpi.ragStatus === 1 ? 2 : 1)
                      setIsOpenCardModal(true)
                    }}
                  >
                    {kpi.ragStatus === 1 ? t('kpi.seeGuidance') : t('kpi.seeBlocker')}
                  </span>
                )}
              </div>
              <div className="grid-cell">{convertFrequencyEnumToText(kpi.frequency)}</div>
              <div className="grid-cell">
                <span>{kpi.lastUpdateTs != 0 ? dayMonthFilter(kpi.lastUpdateTs) : '---'}</span>
              </div>
              <div className="grid-cell">
                <KpiDropdownMenu
                  onClickEdit={() => {
                    setSelectedKpi(kpi)
                    setAddEditModalMode('edit')
                    setIsOpenAddEditKPIModal(true)
                    setSelectedObjId(obj.objective.id)
                  }}
                  fromKpiReport={true}
                  isCompleted={kpi.isCompleted}
                  onClickSeeUpdates={() => {
                    setSelectedKpiUpdate(kpi)
                    setIsOpenKPIUpdatesModal(true)
                    setSelectedObjId(obj.objective.id)
                  }}
                  onClickAddUpdates={() => {
                    setSelectedKpiUpdate(kpi)
                    setIsOpenUpdateModal(true)
                    setSelectedObjId(obj.objective.id)
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    )
  }

  const renderRows = (obj, depth = 0, parentPath = '') => {
    if (!obj) return []

    const uniqueId = `${parentPath}${obj.objective.id}`
    const isExpanded = expandedItems.has(uniqueId)
    const hasChildren = obj.objList?.length > 0
    const hasKpis = obj.kpiList?.length > 0
    const hasAnyChildren = hasChildren || hasKpis
    const rows = []

    rows.push(
      <div
        key={uniqueId}
        className="grid-row objective-row"
        style={{
          alignItems: 'flex-start',
          backgroundColor: 'white',
        }}
      >
        <div className="grid-cell">
          <div
            className="objective-cell"
            style={{ paddingLeft: `${depth * 24}px`, alignItems: 'flex-start' }}
          >
            {hasAnyChildren ? (
              <div
                onClick={() => toggleExpand(obj.objective.id, parentPath)}
                className="expand-button"
              >
                <span className="material-symbols-outlined">
                  {isExpanded ? 'arrow_drop_down' : 'arrow_right'}
                </span>
              </div>
            ) : (
              <span className="expand-button-spacer"></span>
            )}
            <div className="objective-content">
              <div className="objective-statement">
                <div
                  className="color-bar"
                  style={{
                    backgroundColor: `${hexToRGBA(
                      objectiveColors[obj.objective.color],
                      levelOpacity(obj.objective.level),
                    )}`,
                  }}
                />
                <div>{obj.objective.statement}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-cell"></div>
        <div className="grid-cell">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {obj.objective.ownerName != '' && (
              <span className="tooltip" style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
                <NameBox>
                  {obj.objective?.ownerEmployeeName !== null &&
                    obj.objective?.ownerEmployeeName !== undefined &&
                    getFirstLetters(
                      obj.objective.ownerEmployeeName.firstName,
                      obj.objective.ownerEmployeeName.lastName,
                    )}
                </NameBox>
                <span className="tooltiptext">{obj.objective.ownerName}</span>
              </span>
            )}
            {obj.kpiReportStats && (
              <RagList reportStats={obj.kpiReportStats} level={obj.objective.level} />
            )}
          </div>
        </div>
      </div>,
    )

    if (isExpanded) {
      // Add KPIs with their header
      if (hasKpis) {
        rows.push(renderKpiSection(obj.kpiList, uniqueId, depth, obj))
      }

      // Child objectives
      if (hasChildren) {
        obj.objList.forEach((childObj, index) => {
          rows.push(...renderRows(childObj, depth + 1, `${uniqueId}-${childObj.objective.id}-`))
        })
      }
    }

    return rows
  }

  return (
    <div className="grid-kpi-report-new-table-container">
      {isOpenCardModal && cardModalTopicType !== null && (
        <CardModal
          isModalOpen={isOpenCardModal}
          closeModal={() => {
            setIsOpenCardModal(false)
            setCardModalTopicType(null)
          }}
          umtId={cardModalUmtId}
          topicType={cardModalTopicType}
        />
      )}
      {isOpenAddEditKPIModal && selectedObjId && selectedKpi && (
        <AddEditKPIModal
          closeModal={() => {
            setIsOpenAddEditKPIModal(false)
            setSelectedObjId(null)
            setSelectedKpi(null)
          }}
          mode={addEditModalMode}
          objId={selectedObjId}
          kpi={selectedKpi}
          reload={() => {
            reload()
          }}
        />
      )}

      {isOpenKPIUpdatesModal && selectedObjId && selectedKpiUpdate && (
        <KPIUpdatesModal
          handleCancel={() => {
            setIsOpenKPIUpdatesModal(false)
            setSelectedObjId(null)
            setSelectedKpiUpdate(null)
            reload()
          }}
          reloadBackground={() => {
            reload()
          }}
          selectedKpiUpdate={selectedKpiUpdate}
          objId={selectedObjId}
          canAddUpdate={selectedKpiUpdate.isCompleted === false}
          canEditUpdate={selectedKpiUpdate.isCompleted === false}
        />
      )}

      {isOpenUpdateModal && selectedObjId && selectedKpiUpdate && (
        <UpdateKPIModal
          closeModal={() => {
            setIsOpenUpdateModal(false)
            setSelectedObjId(null)
            setSelectedKpiUpdate(null)
          }}
          objId={selectedObjId}
          kpi={selectedKpiUpdate}
          loadUpdates={() => {
            reload()
          }}
        />
      )}

      <div className="kpi-content">
        <div className="grid-kpi-report-new-table-container">
          <div className="grid-body">
            {data?.map((obj, index) => renderRows(obj, 0, `root-${obj.objective.id}-`))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KpiReportNewTable
