import React from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import ApplicationLayout from '../../components/ApplicationLayout'
import InPageLoader from '../../components/InPageLoader'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import Toast from '../../common/toast'
import './style.scss'
import Select from 'react-select'
import ConfigureKPIDashboardModal from '../../components/KPI/ConfigureKPIDashboardModal'
import KpiReportNewTable from './KpiReportNewTable'

const KpiReportByLeaderV2 = ({ hideTitle = false, selectedDaysForShowingWorseKpis = null }) => {
  const { t } = useTranslation(['Common', 'Dashboard'])

  const [isLoading, setIsLoading] = React.useState(false)
  const [filtersSectionOpen, setFiltersSectionOpen] = React.useState(false)
  const [isConfigureKPIDashboardModalOpen, setIsConfigureKPIDashboardModalOpen] =
    React.useState(false)
  const [kpiObjList, setKpiObjList] = React.useState([])
  const [expandAll, setExpandAll] = React.useState(false)
  const [filteredKpiObjList, setFilteredKpiObjList] = React.useState([])

  const [objectiveFilterValue, setObjectiveFilterValue] = React.useState([])
  const [objectiveOwenerFilterValue, setObjectiveOwnerFilterValue] = React.useState(null)
  const [colorFilterValue, setColorFilterValue] = React.useState([])
  const [tagsFilterValue, setTagsFilterValue] = React.useState([])
  const [tagsFilterOptions, setTagsFilterOptions] = React.useState([])
  const [objectiveFilterOptions, setObjectiveFilterOptions] = React.useState([])
  const [kpiOwnerOptions, setKpiOwnerOptions] = React.useState([])
  const [kpiOwnerValue, setKpiOwnerValue] = React.useState([])
  const [objectiveOwnerFilterOptions, setObjectiveOwnerFilterOptions] = React.useState([])
  const [kpiDashboardListDropdown, setKpiDashboardListDropdown] = React.useState([])
  const [kpiDashBoardSelected, setKpiDashBoardSelected] = React.useState(null)

  const [shouldShowCompleted, setShouldShowCompleted] = React.useState(false)
  const canManageKpiDashboards = getConsolidatedConfigSettings('manage_kpi_dashboards') === true

  const specialObjectiveText = localStorage.getItem('tfci') === '44'

  React.useEffect(() => {
    getUsersKPIDashboardAccessList()
  }, [])

  React.useEffect(() => {
    //clear all other filters when kpi dashboard is selected
    if (kpiDashBoardSelected) {
      getKpiDashboardData()
    } else {
      getL2KpiReport()
    }
  }, [kpiDashBoardSelected])

  async function getUsersKPIDashboardAccessList() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(`/get_my_kpi_dashboards_list`)
      if (data.kpiDashboards) {
        setKpiDashboardListDropdown([...data.kpiDashboards])
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  async function getKpiDashboardData() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(
        `/get_kpi_dashboard_data/${kpiDashBoardSelected.kpiDashboardId}`,
      )
      setKpiObjList(data.objKpiList)
      setFilteredKpiObjList(data.objKpiList)
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    getL2KpiReport()
  }, [])

  const getL2KpiReport = async () => {
    try {
      setIsLoading(true)
      let response
      response = await getHttpRequest(`/get_kpi_report_v2`)
      setIsLoading(false)
      setKpiObjList(response.objKpiList)
      setFilteredKpiObjList(response.objKpiList)
      let tags = computeTagsListRecursively(response.objKpiList, [])
      setTagsFilterOptions(tags)
      let kpiOwners = computeKpiOwnerRecursively(response.objKpiList, [])
      setKpiOwnerOptions(kpiOwners)
      let objectives = computeObjectivesRecursively(response.objKpiList, [])
      setObjectiveFilterOptions(objectives)
      let objectiveOwners = computeObjectiveOwenerListRecursively(response.objKpiList, [])
      setObjectiveOwnerFilterOptions(objectiveOwners)
    } catch (error) {
      setIsLoading(false)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
    }
  }

  const colorFilterOptions = [
    { value: 'red', label: t('Common:ragColors.red') },
    { value: 'yellow', label: t('Common:ragColors.amber') },
    { value: 'green', label: t('Common:ragColors.green') },
    { value: 'grey', label: t('Common:ragColors.grey') },
  ]

  const handleChangeObjectiveFilter = (value) => {
    setObjectiveFilterValue(value)
  }

  const handleChangeObjectiveOwnerFilter = (value) => {
    setObjectiveOwnerFilterValue(value?.eId)
  }

  const handleChangeColorFilter = (value) => {
    setColorFilterValue(value)
  }

  const handleChangeTagsFilter = (value) => {
    if (value === null) {
      setTagsFilterValue([])
      return
    }
    setTagsFilterValue([value])
  }

  const handleChangeKpiOwnerFilter = (selectedOptions) => {
    setKpiOwnerValue(selectedOptions)
  }

  const computeTagsListRecursively = (objList, tagsList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.objective.tags.forEach((tag) => {
        if (!tagsList.find((t) => t.tagId === tag.tagId)) {
          tagsList.push(tag)
        }
      })
      computeTagsListRecursively(obj.objList, tagsList)
    }
    return tagsList
  }

  const computeKpiOwnerRecursively = (objList, kpiOwnerList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.kpiList.forEach((kpi) => {
        if (!kpiOwnerList.find((t) => t.eId === kpi.ownerEId)) {
          kpiOwnerList.push({
            name: kpi.ownerName,
            eId: kpi.ownerEId,
          })
        }
      })
      computeKpiOwnerRecursively(obj.objList, kpiOwnerList)
    }
    return kpiOwnerList
  }

  const computeObjectivesRecursively = (objList, objectiveList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      if (obj.objective.id > 0) {
        objectiveList.push(obj.objective)
      }

      computeObjectivesRecursively(obj.objList, objectiveList)
    }
    return objectiveList
  }

  const computeObjectiveOwenerListRecursively = (objList, objectiveOwenerList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      if (!objectiveOwenerList.find((t) => t.eId === obj.objective.ownerId)) {
        if (obj.objective.ownerId > 0) {
          objectiveOwenerList.push({
            name: obj.objective.ownerName,
            eId: obj.objective.ownerId,
          })
        }
      }
      computeObjectiveOwenerListRecursively(obj.objList, objectiveOwenerList)
    }
    return objectiveOwenerList
  }

  React.useEffect(() => {
    let res = JSON.parse(JSON.stringify(kpiObjList))
    if (colorFilterValue.length > 0) {
      let mappedColorFilterValue = colorFilterValue.map((color) => {
        if (color.value === 'red') {
          return 2
        } else if (color.value === 'green') {
          return 0
        } else if (color.value === 'yellow') {
          return 1
        } else if (color.value === 'grey') {
          return -1
        }
      })
      res = filterOutKpisMutiFiltersRecursively(res, mappedColorFilterValue, 'ragStatus')
    }
    if (tagsFilterValue.length > 0) {
      res = filterOutObjsRecursivelyByTags(res, tagsFilterValue[0])
    }
    if (kpiOwnerValue.length > 0) {
      res = filterOutKpisMutiFiltersRecursively(
        res,
        kpiOwnerValue.map((owner) => owner.eId),
        'ownerEId',
      )
    }
    if (shouldShowCompleted) {
      res = filterOutKpisRecursively(res, true, 'isCompleted')
    } else {
      res = filterOutKpisRecursively(res, false, 'isCompleted')
    }
    if (objectiveOwenerFilterValue) {
      res = filterOutObjsRecursivelyByOwner(res, objectiveOwenerFilterValue)
    }
    if (objectiveFilterValue.length > 0) {
      res = filterOutObjsRecursivleyByObjective(res, objectiveFilterValue)
    }
    setFilteredKpiObjList(res)
  }, [
    colorFilterValue,
    tagsFilterValue,
    kpiOwnerValue,
    objectiveFilterValue,
    objectiveOwenerFilterValue,
    shouldShowCompleted,
    kpiObjList,
  ])

  const filterOutKpisRecursively = (objList, value, key) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.kpiList = obj.kpiList.filter((kpi) => kpi[key] == value)
      filterOutKpisRecursively(obj.objList, value, key)
    }
    return objList
  }

  const filterOutKpisMutiFiltersRecursively = (objList, filterValueArray, key) => {
    if (objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      // Filter KPIs based on the filter criteria
      obj.kpiList = obj.kpiList.filter((kpi) => filterValueArray.includes(kpi[key]))

      // Recursively filter child objectives
      const filteredChildren = filterOutKpisMutiFiltersRecursively(
        obj.objList,
        filterValueArray,
        key,
      )
      obj.objList = filteredChildren

      // Only keep this objective if it has KPIs or its children have KPIs
      if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
        return [
          {
            ...obj,
            objList: filteredChildren,
          },
        ]
      }
      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivelyByTags = (objList, tag) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesTag = obj.objective.tags.some((t) => t.tagId === tag.tagId)

      if (matchesTag) {
        //filter out children has at least 1 kpi
        const filteredChildren = obj.objList.filter((child) => child.kpiList.length > 0)
        // Only return if this objective or its children have KPIs
        if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
          return {
            ...obj,
            objList: filteredChildren,
          }
        }
        return []
      }

      const filteredChildren = filterOutObjsRecursivelyByTags(obj.objList, tag)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivelyByOwner = (objList, ownerId) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesOwner = parseInt(obj.objective.ownerId) === parseInt(ownerId)

      if (matchesOwner) {
        const filteredChildren = obj.objList.filter((child) => child.kpiList.length > 0)
        // Only return if this objective or its children have KPIs
        if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
          return {
            ...obj,
            objList: filteredChildren,
          }
        }
        return []
      }

      const filteredChildren = filterOutObjsRecursivelyByOwner(obj.objList, ownerId)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }
      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivleyByObjective = (objList, objectiveList) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesObjective = objectiveList.some((t) => t.id === obj.objective.id)
      if (matchesObjective) {
        const filteredChildren = obj.objList
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      const filteredChildren = filterOutObjsRecursivleyByObjective(obj.objList, objectiveList)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      return []
    })

    return filteredList
  }

  const resetFilter = () => {
    setTagsFilterValue([])
    setColorFilterValue([])
    setKpiOwnerValue([])
    setObjectiveOwnerFilterValue(null)
    setObjectiveFilterValue([])
  }

  return (
    <>
      {isConfigureKPIDashboardModalOpen && (
        <ConfigureKPIDashboardModal
          closeModal={() => {
            setIsConfigureKPIDashboardModalOpen(false)
            getUsersKPIDashboardAccessList()
          }}
        />
      )}
      <div
        style={{
          paddingTop: selectedDaysForShowingWorseKpis ? '0rem' : '3rem',
          paddingLeft: '1rem',
        }}
      >
        {!hideTitle && (
          <>
            <div style={{ textAlign: 'left' }}>
              <b style={{ justifySelf: 'flex-start', alignSelf: 'center', fontSize: '20px' }}>
                {t('kpi.kpiReportTitle')}
              </b>
            </div>
            <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedDaysForShowingWorseKpis === null && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '1rem',
                  marginTop: '1rem',
                }}
              ></div>
              <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
            </>
          )}

          {isLoading && <InPageLoader />}
          {!isLoading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '0.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.5rem',
                    marginRight: 'auto', // Add margin-right: auto to push element to left
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>
                        {t('Common:formPlaceHolder.selectKPIDashboard')}:
                      </span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '17vw' }}>
                      <SelectSingleField
                        placeholder={t('Common:formPlaceHolder.selectKPIDashboard')}
                        name="selectLeader"
                        value={kpiDashboardListDropdown?.filter(
                          (dashboard) =>
                            dashboard?.kpiDashboardId === kpiDashBoardSelected?.kpiDashboardId,
                        )}
                        options={kpiDashboardListDropdown}
                        onChange={(value) => {
                          resetFilter()
                          setKpiDashBoardSelected(value)
                        }}
                        getOptionLabel={(option) => option.kpiDashboardName}
                        getOptionValue={(option) => option.kpiDashboardId}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
                {canManageKpiDashboards && (
                  <div
                    className="sleek-button sleek-blue"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsConfigureKPIDashboardModalOpen(true)
                    }}
                  >
                    {t('Common:kpi.manageDashboards')}
                  </div>
                )}

                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShouldShowCompleted((prev) => !prev)
                  }}
                >
                  {shouldShowCompleted ? t('kpi.showUncompleted') : t('kpi.showCompleted')}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '8rem' }}>
                  <div
                    className="sleek-button sleek-blue"
                    onClick={() => {
                      resetFilter()
                    }}
                    //style={{ fontSize: '12px', height: '1.8rem' }}
                  >
                    <span>{t('Common:commonButtons.clearFilters')}</span>
                  </div>
                </div>
              </div>

              <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.5rem 0' }} />
              <div
                style={{
                  color: 'rgb(0, 102, 204)',
                }}
              >
                {t('Common:filter.selectOneFilter')}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>
                      {specialObjectiveText ? t('Common:filter.pillar') : t('Common:filter.l1Obj')}:
                    </span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }}>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 1)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 1)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>
                      {specialObjectiveText
                        ? t('Common:filter.macroInitiative')
                        : t('Common:filter.l2Obj')}
                      :
                    </span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }}>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 2)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 2)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>
                      {specialObjectiveText ? t('Common:filter.vehicle') : t('Common:filter.l3Obj')}
                      :
                    </span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }}>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 3)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 3)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  onClick={() => {
                    setFiltersSectionOpen(!filtersSectionOpen)
                  }}
                >
                  {t('Common:filter.advanced')}
                  <span
                    className="material-symbols-outlined"
                    style={{
                      transform: filtersSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    expand_more
                  </span>
                </div>
              </div>
              {filtersSectionOpen && (
                <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.5rem 0' }} />
              )}
              {filtersSectionOpen && (
                <div
                  style={{
                    color: 'rgb(0, 102, 204)',
                  }}
                >
                  {t('Common:filter.advancedFilter')}
                </div>
              )}
              {filtersSectionOpen && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.objectiveOwner')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '13vw' }}>
                      <SelectSingleField
                        placeholder={t('formPlaceHolder.selectLeader')}
                        name="selectLeader"
                        value={objectiveOwnerFilterOptions?.filter(
                          (person) => parseInt(person.eId) === parseInt(objectiveOwenerFilterValue),
                        )}
                        options={objectiveOwnerFilterOptions}
                        onChange={handleChangeObjectiveOwnerFilter}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.eId}
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiStatus')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '8vw' }}>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        value={colorFilterValue}
                        isMulti
                        onChange={handleChangeColorFilter}
                        getOptionLabel={(option) => option?.label}
                        getOptionValue={(option) => option?.value}
                        options={colorFilterOptions}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.tags')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '8vw' }}>
                      <Select
                        placeholder={t('Common:formPlaceHolder.select') + '...'}
                        value={tagsFilterValue}
                        isMulti={false}
                        onChange={handleChangeTagsFilter}
                        getOptionLabel={(option) => option?.tagName}
                        getOptionValue={(option) => option?.tagId}
                        options={tagsFilterOptions}
                        isClearable={true}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiOwner')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '10vw' }}>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        value={kpiOwnerValue}
                        isMulti={true}
                        onChange={handleChangeKpiOwnerFilter}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.eId}
                        options={kpiOwnerOptions}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                    paddingLeft: '0.8rem',
                  }}
                ></div>
              </div>
            </div>
          )}
          <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.3rem 0' }} />
          <div style={{ textAlign: 'left', marginBottom: '1rem' }}>
            <span
              onClick={() => setExpandAll(!expandAll)}
              style={{
                color: '#0066cc',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {expandAll
                ? t('Common:commonButtons.collapseAll')
                : t('Common:commonButtons.expandAll')}
            </span>
          </div>
          {!isLoading && (
            <KpiReportNewTable
              data={filteredKpiObjList}
              expandAll={expandAll}
              reload={kpiDashBoardSelected ? getKpiDashboardData : getL2KpiReport}
            />
          )}
        </div>
      </div>
    </>
  )
}

const KpiReportByLeaderWithContextv2 = () => {
  return <ApplicationLayout>{<KpiReportByLeaderV2 showLeaderDropdown={true} />}</ApplicationLayout>
}

export { KpiReportByLeaderV2, KpiReportByLeaderWithContextv2 }
